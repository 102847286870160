<template>
  <cards-container class="progress-bars-card" :title="title" :date="date" :redirectionLink="redirectionLink">
    <template v-slot:body>
      <v-row class="body">
        <v-col cols="6" class="pt-0 pb-0 pl-0 m-auto">
          <v-list-item-title class="mb-2">
            <span class="text-h5">{{ $t('score') }} </span>
            <span class="text-h4">{{ data.score }}</span>
            <small> {{ $t('of') }} {{ data.totalScore }}</small>
          </v-list-item-title>

          <v-list-item-subtitle>
            <span class="incline-decline">
              <span class="icomoon icon-up"></span>
              4
            </span>
            {{  $t("last") }} 7 {{  $t("days") }}
          </v-list-item-subtitle>
        </v-col>

        <v-col cols="6" class="pt-0 pb-0 pr-0">
          <div class="progress-bars-container d-flex">
            <v-progress-linear
              v-for="i in 6"
              :key="i"
              :value="(Math.random() * (100 - 10 + 1)) + 10"
              :color="$store.getters['auth/user'].theme_config.progress_bar_primary_color"
              :style="`transform: rotate(270deg) translate(35px, ${
                i * 30 - 15
              }px); margin-bottom:${i * 10};`"
              background-color="#DBDCDD"
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </template>
  </cards-container>
</template>

<script>
import CardsContainer from './CardsContainer.vue'

export default {
  name: "SingleProgressbarSetCard",

  props: {
    title: 
    {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    }
  },

  components: {
    CardsContainer
  }
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/single-progressbar-set-card.scss">
</style>